/* eslint-disable */

import Vue from 'vue';
import Router from 'vue-router'

Vue.use(Router)

const routes = [
	{
		path: '/',
		name: 'Root',
		redirect: '/login'
	},
	// ログイン
	{
		path: '/login',
		name: 'Login',
		component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
		// props: true,
	},
	// ユーザー登録
	{
		path: '/register',
		name: 'Register',
		component: () => import(/* webpackChunkName: "register" */ '../views/Register/Register.vue'),
	},
	// 	通知用
	{
		path: '/notification',
		name: 'Notification',
		component: () => import(/* webpackChunkName: "notification" */ '../views/Notification.vue'),
		// props: true,
	},
	{
		path: '/common',
		name: 'Common',
		component: () => import(/* webpackChunkName: "common" */ '../views/common/Common.vue'),
		children: [
					// Reports
					{
						path: 'home',
						name: 'Home',
						component: () => import(/* webpackChunkName: "home" */ '../views/Reports/Home.vue')
					},
					{
						path: 'detail',
						name: 'Detail',
						component: () => import(/* webpackChunkName: "detail" */ '../views/Reports/Detail.vue')
					},
					{
						path: 'upload',
						name: 'Upload',
						component: () => import(/* webpackChunkName: "upload" */ '../views/Reports/Upload.vue')
					},
					{
						path: 'fire',
						name: 'Fire',
						component: () => import(/* webpackChunkName: "fire" */ '../views/Reports/Fire.vue')
					},
					// takeno関連
					{
						path: 'takeno',
						name: 'Takeno',
						component: () => import(/* webpackChunkName: "takeno" */ '../views/Takeno/Takeno.vue'),
					},
					{
						path: 'gift',
						name: 'Gift',
						component: () => import(/* webpackChunkName: "gift" */ '../views/Takeno/Gift.vue'),
					},
					{
						path: 'purchase',
						name: 'Purchase',
						component: () => import(/* webpackChunkName: "purchase" */ '../views/Takeno/Purchase.vue'),
					},
					{
						path: 'gacha',
						name: 'Gacha',
						component: () => import(/* webpackChunkName: "gacha" */ '../views/Takeno/Gacha.vue'),
					},
					// Target
					{
						path: 'progress',
						name: 'Progress',
						component: () => import(/* webpackChunkName: "progress" */ '../views/Target/Progress.vue')
					},
					// User
					{
						path: 'setting',
						name: 'Setting',
						component: () => import(/* webpackChunkName: "setting" */ '../views/User/Setting.vue')
					},
					{
						path: 'itemlist',
						name: 'ItemList',
						component: () => import(/* webpackChunkName: "itemlist" */ '../views/User/ItemList.vue')
					},
					{
						path: 'memo',
						name: 'Memo',
						component: () => import(/* webpackChunkName: "memo" */ '../views/User/Memo.vue')
					},
		]
	},
	// それ以外のURLは強制的にログイン画面へ遷移させる
	{
		path: '*',
		redirect: { name : 'Login' },
	}
]

const router = new Router({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

export default router

// addEventListener("popstate", () => {
// 	if (router.currentRoute === '/login') {
// 		router.go({path: router.currentRoute.path, force: true})
// 	}
// 	else{
// 		router.push({name: 'Login'})
// 	}
// })