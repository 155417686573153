import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from "./plugins/vuetify.js"
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueStar from 'vue-star'
import VueCarousel from 'vue-carousel'

// カルーセル
Vue.use(VueCarousel)

// いいね
Vue.component('VueStar', VueStar)

Vue.config.productionTip = false

Vue.use(VueAxios, axios)

import '@/style/common.scss'

import Mixin from '@/components/mixin/common.js'
Vue.mixin(Mixin)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
