import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		// ログイン情報
		loginInfo: {
			userId: '',
			name: '',
			color: '#235bc8',
			img: '',
			takeno: 0,
		},
        loading: false,         // ローディング
		// エラーダイアログ
		dialog: {
			title: '',
			message: '',
		},
		page: '', // 右上アイコンメニュー
	},
	mutations: {
		setLoginInfo(state, payload) {
			state.loginInfo.name = payload.name
			state.loginInfo.userId = payload.userId
			state.loginInfo.color = payload.color
			state.loginInfo.img = payload.img
			state.loginInfo.takeno = payload.takeno
		},
        // 現在loading中か判別
        nowLoading(state, payload) {
            state.loading = payload
        },
		setDialog(state, payload) {
			state.dialog.title = payload.title
			state.dialog.message = payload.message
		},
		clearDialog(state) {
			state.dialog.title = ''
			state.dialog.message = ''
		},
		setPage(state, payload) {
			state.page = payload
		},
	},
	actions: {
        // ログイン情報をセット
        setLoginInfo(store, payload) {
            store.commit('setLoginInfo', payload)
        },
        // ローディング
        nowLoading(store, payload) {
            store.commit('nowLoading', payload)
        },
        // ダイアログ
        setDialog(store, payload) {
            store.commit('setDialog', payload)
        },
        // アイコンselectedメニュー
        setPage(store, payload) {
            store.commit('setPage', payload)
        },
	},
	getters: {
		loginInfo: state => state.loginInfo,
        getLoading: state => state.loading,
		dialog: state => state.dialog,
		page: state => state.page,
	}
})
