<template>
<!-- eslint-disable -->
<v-app>
	<Dialog />
	<router-view/>
</v-app>
</template>

<script>
/*eslint-disable */
import Dialog from '@/components/Dialog.vue'
export default {
	name: 'App',
	components: { Dialog },
	data() {
		return {
		}
	},
	methods: {
	},
}
</script>
