export default {
    computed: {
    },
    methods: {
        // 現在年月日をYYYYMDD形式で取得
        getNowYMD() {
            var dt = new Date();
            var y = dt.getFullYear()
            var m = ("00" + (dt.getMonth()+1)).slice(-2)
            var d = ("00" + dt.getDate()).slice(-2)
            var result = y + m + d
            return result
        },
        commonError(data) {
            if (data.response) {
                // スターテスエラー
                if (data.response.status == 400 && data.response.data.status == 401) {
                    this.goToLogin()  // セッション切れ
                } else if (data.response.data) {
                    let msg = data.response.data.message
                    if (!msg) msg = '意図しないエラーが発生しました。\nシステム管理者にお問い合わせ下さい。'
                    this.setErrorDialog({ title: '予期せぬエラー', message: msg })
                }
            } else {
                // ネットワーク接続エラー
                this.networkError()
            }
        },
        goToLogin() {
			if (this.$route.path !== '/') this.$router.push({name : 'Login'})
        },
    },
}